<template>
    <div class="">
        <div style="padding: 0 5px">
            <h3>杭州怀湖网络科技有限公司隐私政策</h3>
            <p></p>
            <p>更新日期：2023年1月13日</p>
            <p>生效日期：2023年1月13日</p>
            <p></p>
            <p>
                <strong>此特别提醒您（用户）在注册成为用户之前，请认真阅读本《隐私政策》（以下简称“本政策”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。</strong>
            </p>
            <p>用户的注册、登录、使用等行为将视为对本政策的接受，并同意接受本协议各项条款的约束。</p>
            <p>本产品是由杭州怀湖网络科技有限公司（以下简称“我们”或“本公司”）开发运营，本协议约定本公司与用户（以下简称为“您）之间关于软件或服务的权利义务。“用户”是指注册、登录、使用本公司产品或服务的主体。出于业务、技术的发展以及更好提供服务的目的，我们会适时对本隐私协议进行修订，新修订的隐私协议发布之后将完全代替之前版本的隐私协议，请您及时了解查阅相关内容，恕不再另行通知。如果您对修改后的隐私协议条不能接受，请立即停止使用本公司提供的产品和服务，用户继续使用本公司产品或服务将被视为接受修改后的协议。
            </p>
            <p>
                <br />
            </p>
            <p>本政策将帮助您了解：</p>
            <p>我们完全遵循本政策来收集和使用您的有限的信息，其目的为了帮助您获得更好的服务。</p>
            <p>当您使用或开启相关功能或接受服务时，为确保功能、服务得以实施，我们会收集必要信息。除非完全必要，否则大部分情况下您无需提供这部分信息。</p>
            <p>您可以选择账户注册方式登录应用，如果没有登录，则应用本身会使用设备标识符信息来建立对您的账户关联，以便于及时提供相应服务。</p>
            <p>除非经过您的允许，否则应用不会开启非必要权限。即便开启了经过您授权的非必要权限，您也可以随时在本应用或设备操作系统中停止授权。</p>
            <p>本公司竭力提供安全、绿色、稳定的应用，如您对本政策有任何疑问，也可以随时联系我们以获得及时的解答。</p>
            <p>以下我们将全面介绍我们是如何对本应用可能涉及到的个人信息进行收集和处理的：</p>
            <p>1、我们如何收集和使用您的个人信息</p>
            <p>2、您的个人信息使用规则</p>
            <p>3、我们如何共享、转让、公开披露您的个人信息</p>
            <p>4、我们如何保护您的个人信息</p>
            <p>5、您的权利</p>
            <p>6、未成年保护</p>
            <p>7、关于第三方SDK的特殊说明</p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-size: 16px;">
                    <strong>一、我们如何收集和使用您的个人信息</strong>
                </span>
            </p>
            <p>（一）在您使用我们网络服务的过程中，我们网络会按照依法、正当、必要等原则，仅为实现明确、合理、必要的处理目的前提下，按照以下所列的方式收集您在使用服务时主动提供的、或因为使用服务而产生的信息，以用于保障您的账号安全、及向您提供、优化我们网络服务。
            </p>
            <p>1.1 注册我们账号、使用我们网络服务：</p>
            <p>1.1.1当你使用我们网络服务时，您需要提供您本人的手机号码注册我们账号，届时我们会通过短信验证码鉴权的方式验证您的手机号码是否真实有效并处于正常使用状态。当您的短信验证码鉴权通过后，您将成功注册我们账号。</p>
            <p>1.1.2
                当您获得游戏账号后，为符合《中华人民共和国网络安全法》等法律法规以及监管机构的要求（包括游戏用户必须通过网络游戏防沉迷实名认证系统或委托合作的合法设立运营的第三方机构的验证完成实名注册及未成年人识别等），我们还需要您提供您的姓名及身份证号码通过实名认证后方可使用我们网络服务。需要提请您注意的是，如因您在账号注册实名认证阶段填写的姓名及身份证号码信息无法通过网络游戏防沉迷实名认证系统或委托合作的合法设立运营的第三方机构的验证的，需要您根据实名认证系统的引导上传身份证明文件资料，以人工认证的形式完成实名认证。我们承诺，我们将依法、合理并尽一切合理保密措施保护您个人信息安全。
            </p>
            <p>1.2
                为确保您的我们账号安全、您能正常使用我们网络服务及游戏基础功能，确保网络运营及服务安全，营造公平、安全健康的网络游戏环境，且为了在服务过程中不断优化游戏产品性能提高为您提供的游戏服务质量，我们会收集您的如下信息：
            </p>
            <p>
                <span style="text-decoration: underline;">
                    <strong>
                        1.2.1
                        为识别及确保用户及设备的真实性及唯一性、确保游戏账户及环境安全、避免黑灰色产业恶意注册账号从事不法行为，我们会收集您的设备信息，包括：获取硬件序列号、蓝牙地址、Oaid、运营商、CPU数据、SD卡
                        <strong style="white-space: normal;">
                            <strong>
                                数据<strong>、</strong>
                            </strong>
                        </strong>
                        时区、手机型号、设备名称、设备类型和版本、总存储容量、设备内存大小、设备用户名、系统版本、唯一设备标识符（android
                        ID/IMEI（如您同意授权）/IDFA（如您同意授权）/OPENUDID/GUID/IDFV/Mac地址/IMSI）、开机时间、分辨率、应用ID、IP地址、网络类型、APP安装时间、语言、国家地区码、系统更新时间信息。如我们为您提供互联网信息服务的，我们将会依据法律规定，在账号信息页面展示您合理范围内的互联网协议（IP）地址归属地信息，便于公众为公共利益实施监督。
                    </strong>
                </span>
            </p>
            <p>
                1.2.2我们会收集您的游戏日志信息，包括登录日志、物品日志、操作信息、游戏对局（战）信息、游戏内好友记录/列表及其互动记录，以便用于查看您的游戏历史记录、客服处理客诉、游戏账户安全保护、游戏安全分析。该等游戏日志信息也可能会同步到游戏后续更新的版本。<br />
            </p>
            <p>
                <span style="text-decoration: underline;">
                    <strong>1.2.3
                        为确保游戏账户安全保护、游戏安全分析及防范打击危害网络安全、游戏公平环境的行为（包括但不限于盗版软件、外挂、作弊等影响游戏正常进程的第三方插件/软件），我们会收集游戏识别信息、硬件及操作系统信息、屏幕操作点击坐标、传感器数据（加速度计、陀螺仪、重力加速度计项目）、剩余电量、已安装的软件/应用列表、运行中的进程、全部应用的耗电情况、当前应用的跳出次数及游戏崩溃记录信息。</strong>
                </span>
            </p>
            <p>1.2.4
                当您在我们网络服务消费时，为了完成订单支付、交付服务或物品、确认交易状态以及提供客诉处理服务，我们会收集您的游戏订单记录、充值记录、消费记录及其相关创建时间、交易类型、交付记录信息，以确保向您展示和管理您的交易记录，并尽最大程度保护您的游戏内虚拟物品安全。收集充值记录、消费记录系为确保实现我们产品的消费功能所必须，否则将无法完成充值消费交易。
            </p>
            <p>1.3
                您在使用我们产品或服务时，如通过发言、跟帖、留言、图片、语音、视频及其他方式等方式进行互动时，我们可能会收集并保存您所发布的上述信息，并且会使用您的头像、昵称、游戏ID或游戏角色形象用户展示相关发布内容，且您展示、发布的前述信息将会受到人工或系统自动检测，用于过滤违法、色情、暴恐、政治、辱骂、恶意广告等不当内容，以确保网络、游戏环境的安全健康。
            </p>
            <p>1.4 客户需求响应、客诉处理</p>
            <p>1.4.1
                当您联系客服时，为确保您的账户、财产及系统安全，我们会需要您提供必要的个人信息进行身份验证，特别是涉及个人信息保护投诉或建议、未成年人游戏相关投诉或建议或其他客户投诉和需求。为处理您的诉求，我们的人工客服会在获得您的授权后在您授权范围内查询或核验您的相关信息，我们将尽可能采取技术和管理措施保障您的个人信息安全和保密，并确保在必要范围内进行使用相关信息。
            </p>
            <p>1.4.2
                我们可能还会保存您的联系方式（您用于联系客户的或主动提供的手机号码、微信号、QQ号或其他联系方式）、您与我们的通信记录和内容，以及其他必要信息，以便为您提供和记录客户服务。且我们也可能在您主动提供的情况下，收集您的手机号码，用于您所参与活动的结果通知、游戏故障的报告、以及您所咨询事宜的反馈与沟通，或经您同意向您推送您感兴趣的信息或推广信息。
            </p>
            <p>
                <strong>1.5 设备权限功能的启用</strong>
            </p>
            <p>
                <strong>1.5.1 如您同意访问设备的存储权限，即我们获得您的同意访问您设备的存储空间，以确保可以下载并保存内容，或者通过缓存来实现内容分享发布服务功能；</strong>
            </p>
            <p>
                <strong>1.5.2
                    如您同意访问电话信息权限，即我们获得您的同意获取您的设备信息以作为您设备的唯一标识，用于了解和提升游戏产品的设备适配性、识别游戏服务异常状态，确保游戏服务的网络、运营以及游戏账号安全；</strong>
            </p>
            <p>
                <strong>1.5.3
                    如您同意访问相机权限，您可实现上传、拍摄照片/图片/视频，实现发布信息和评论、视频直播（如该产品有提供）或与客服沟通提供证明等功能，如您不同意我们访问相机权限，将影响您正常使用游戏内拍摄照片/图片/视频等功能，但不会影响您正常使用我们产品，您可以随时取消本项权限授权；</strong>
            </p>
            <p>
                <strong>1.5.4
                    如您同意访问相册/图库权限，您可实现游戏内截图保存，如截图保存注册账号、游戏截图用于社交软件分享等功能，如您不同意我们访问相册/图库权限，将影响您正常使用游戏内截图等功能，但不会影响您正常使用我们产品，您可以随时取消本项权限授权；</strong>
            </p>
            <p>
                <strong>1.5.5
                    如您同意访问麦克风权限，您可实现语音聊天、发送语音信息等功能，如您不同意我们访问麦克风权限，将影响您正常使用语音聊天、发送语音信息等功能，但不会影响您正常使用我们产品，您可以随时取消本项权限授权；</strong>
            </p>
            <p>
                <strong>1.5.6
                    特别提示：如您选择关闭特定功能而同意授权的设备访问权限，市面上大部分的设备都能支持实现您的要求，具体方法根据您的设备生产商而定。我们需要提醒您，如您同意开启任意权限即意味着同意授权我们收集和使用相关信息，以此为您提供对应服务。如您一旦关闭任意权限即意味着您取消了该项的授权，我们将不再获得相应授权基础继续收集和使用相关信息，也无法再提供该权限所对应的服务。但是，您关闭某项权限取消授权的决定，并不会影响关闭权限取消授权所进行的信息收集及使用。</strong>
            </p>
            <p>
                <span style="font-size: 14px;">（二）</span>
                请您知悉，根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：
            </p>
            <p>1、为履行法定职责或者法定义务所必需；</p>
            <p>2、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
            <p>3、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
            <p>4、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
            <p>5、法律法规规定的其他情形。</p>
            <p>（三）为了配合司法机关工作、执法机关工作、司法程序之需要（包括但不限于民事诉讼程序、刑事案件处理、违法行为处理、配合公检法机关协查要求或配合执法机关工作要求），我们可能会向该等有权执法/司法机关提供涉诉（案）材料等，其中可能会包含您的账号、手机号、充值记录和服务使用情况等信息。
            </p>
            <p>（四）我们确认并承诺，一旦需要将您的个人信息用于本政策内未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过弹窗、页面提示或其他方式另行征得您的同意。</p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-size: 16px;">
                    <strong>二、您的个人信息使用规则</strong>
                </span>
            </p>
            <p>（一）我们会根据本政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用和保存。</p>
            <p>（二）在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
            </p>
            <p>（三）请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将视为您同意在您使用我们的产品与/或服务期间持续授权我们使用。</p>
            <p>（四）我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方发布、共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势我们承诺，统计信息并不包含您的任何身份识别信息。</p>
            <p>（五）当我们必须展示您的个人信息 （如对抽奖信息进行公示） 时，我们会采）用包括内容替换部分信息、匿名化处理等适当的、合理的、有效的处理方式对您的信息进行脱敏，以保护您的个人信息安全。</p>
            <p>（六）当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，我们将再次向您进行说明，并征得您的同意。</p>
            <p>（七）在您使用我们服务期间，我们将保存您的个人信息。当您停止使用我们服务时，我们将按照《中华人民共和国网络安全法》等法律法规的规定处理您的个人信息。</p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-size: 16px;">
                    <strong>三、我们如何共享、转让、公开披露您的个人信息</strong>
                </span>
            </p>
            <p>（一）共享</p>
            <p>我们不会与我们以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
            <p>1、事先获得您明确的同意或授权；</p>
            <p>2、根据法律法规、监管政策、行政机关和/或司法机关合法程序的要求；</p>
            <p>3、在法律法规允许的范围内，为了维护我们及我们的关联方或合作伙伴、您或其他我们用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
            <p>4、应您基于处理您与他人的纠纷或争议的过程中，根据法律法规或有权机关的合法要求；</p>
            <p>5、应您的监护人合法要求而提供您的信息；</p>
            <p>6、基于符合法律法规的社会公共利益而提供；</p>
            <p>7、符合与您签署的相关协议 （包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
            <p>8、与授权合作伙伴及其他第三方共享：仅为实现我们网络服务，且向您告知接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并取得您的单独同意后，我们方可能会与合作伙伴及其他第三方分享您的信息以实现您需要的核心功能或提供您需要的服务，维护和改善我们的服务。我们仅会出于合法、正当、必要、特定、明确目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴及其他第三方无权将共享的个人信息用于任何其他用途；
            </p>
            <p>9、与我们的关联企业共享：为能让您更便捷体验我们的关联企业提供的服务您的个人信息可能会与我们的关联企业共享。我们只会共享必要的个人信息，且受本政策条款的约束。如要改变个人信息的处理目的或处理方式，个人信息处理方都必须再次征求您的授权同意；
            </p>
            <p>10、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护我们、您或其他我们用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
            </p>
            <p>就上述与我们共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照不低于我们的政策要求以及其他任何相关的保密和安全措施来处理信息。</p>
            <p>（二）转让</p>
            <p>我们不会与我们以外的任何公司、组织和个人转让您的个人信息，但以下情况除外：</p>
            <p>1、事先获得您明确的同意或授权；</p>
            <p>2、在涉及合并、收购、分立、解散、被宣告破产、资产转让或类似的交易时而不得不涉及到个人信息转让，我们会告知您接收您信息的公司或组织的名称和联系方式，并要求新的持有您信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意；
            </p>
            <p>3、法律法规规定的合法情形。</p>
            <p>（三）公开披露</p>
            <p>我们仅会在以下情况下，且采取符合或不低于业界通行的、公认的、标准的安全防护措施的前提下，才会披露您的信息：</p>
            <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
            <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，在符合法律法规的前提下，我们在收到前述披露信息的请求后，会要求请求方必须出具与之请求相应的、具有法律效力的法律文件，如传票或调（协）查函等。我们将会对该请求及相关法律文件进行合理的、谨慎的审查以确认请求的合法性。我们将严格依据该文件内所明确要求的信息类型和披露方式披露您的信息，且确保仅限于该有效法律请求的特定调查目的所需的必要数据/信息。
            </p>
            <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
            <p>在以下所述情形中，我们共享、转让、公开披露个人信息不必事先征得您本人的授权同意：</p>
            <p>1、为履行法定职责或者法定义务所必需；</p>
            <p>2、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
            <p>3、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
            <p>4、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
            <p>5、法律法规规定的其他情形。</p>
            <p>
                <br />
            </p>
            <p>
                <strong>
                    <span style="font-size: 16px;">四、我们如何保护您的个人信息</span>
                </strong>
            </p>
            <p>（一）我们严格遵守法律法规的规定，在中国境内收集的用户个人信息将存储于中国境内。</p>
            <p>（二）我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非根据法律规定或基于为本政策或服务协议的目的而需要延长保留期。</p>
            <p>（三）我们将采取以下手段保护您的信息：我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、采取适当的安全技术措施来防止您的信息遭到未经授权的访问、使用、公开披露、修改、损坏或丢失。</p>
            <p>（四）我们会采取一切合理可行的技术措施，确保未存在未经许可收集无关的个人信息。</p>
            <p>（五）我们善意地提醒您，鉴于互联网并非绝对安全的环境，且受现有技术发展的限制，我们只能尽可能以合理的、符合行业规范的确保信息安全，由于您与其他我们用户通过游戏聊天等交流方式并未进行加密保护，因此强烈建议并再次郑重您切勿通过前述方式发送个人信息。我们也希望您的账号能使用复杂的密码，以协助我们保证您的账号和个人信息安全。
            </p>
            <p>（六）我们将尽力确保或担保您个人信息的安全。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏导致您的合法权益受损，我们将承担相应的法律责任。</p>
            <p>（七）如发生个人信息安全事件后，我们将按照法律法规的要求，主动上报个人信息安全事件的处理情况，并及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议对您的补救措施等。我们将及时将事件相关情况根据您留存的联系方式，以电子邮件、信函、电话、游戏内推送通知等方式告知您。如存在难以逐一告知个人信息主体的情况时，我们会采取合理、有效的方式发布公告。
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-size: 16px;">
                    <strong>五、您的权利</strong>
                </span>
            </p>
            <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
            <p>（一）查阅、复制、更正您的个人信息</p>
            <p>您可通过我们客服或我们网络软件联系我们，对您的个人信息进行查阅、复制、修正。我们将会按照通行的技术标准和惯例，尽可能保证您能够查阅复制、更正您的个人信息。</p>
            <p>（二）删除您的个人信息</p>
            <p>在以下情形中，您可以通过我们客服提出删除个人信息的请求：</p>
            <p>1、我们并没有按照法律法规和监管政策的要求处理个人信息；</p>
            <p>2、我们在没有获得您的同意和/或授权的情况下，擅自收集、使用您的个人信息；</p>
            <p>3、我们没有按照《我们网络用户服务协议》和/或本政策的约定处理个人信息；</p>
            <p>4、根据国家法律法规的规定以及《我们网络用户服务协议》，您提出注销游戏账号且符合注销条件并完成注销程序的；</p>
            <p>5、我们或我们的产品终止服务及运营；</p>
            <p>6、您已按照本协议第六条第 （三） 款约定，撤回您的授权。</p>
            <p>若我们认为您的删除请求符合规定决定操作的，我们还将同时通知根据本政策约定从我们获得您的个人信息的实体/组织，要求其及时删除您的个人信息，除非该等实体/组织已经另行获得您的独立授权或根据法律法规要求应予保留。</p>
            <p>当您的个人信息在我们提供的服务中被删除后，我们由于技术原因和更新安排可能不会立即从备份系统中删除相应的信息，但确保会在最近一次的系统备份更新时进行删除操作。</p>
            <p>若您的个人信息的法定保存期限未届满，或者删除个人信息从技术上难以实现的，我们将停止除存储和采取必要的安全保护措施之外的处理。</p>
            <p>（三） 改变您授权同意的范围或撤回您的授权</p>
            <p>除法律法规另有规定外，基于个人同意处理个人信息的，您可以随时、不受限制地对不属于提供我们网络服务所必要收集的个人信息的授权和功能进行关闭或限制。您可以在终端设备通过修改开放权限、关闭相关设备功能等方式改变您授权同意的权限，该变更操作即时生效，我们将根据您开放的权限提供相应的我们网络服务。
            </p>
            <p>（四） 回复、响应您的权利请求</p>
            <p>为确保个人信息安全和服务质量，我们会根据您的一项或多项的请求需要您提供书面的请求文件以及身份证件、充值记录等的必要身份验证资料，以验证您的身份及实际使用情况。为确保不会对个人信息或数据错误操作或处理我们会根据相关指引先行验证您的身份信息，验证通过再处理您的请求。
            </p>
            <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将根据情况不同酌情收取一定成本费用。而对于那些非必要非现有技术手段和系统可以解决的、不符合现行行业操作管理的、给他人合法权益带来风险或者非常不切实际的请求，我们有权拒绝该等请求。
            </p>
            <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
            <p>1、与国家安全、国防安全直接相关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p>4、有充分证据表明您存在主观恶意或滥用权利的；</p>
            <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
            <p>6、涉及商业秘密的；</p>
            <p>7、违反法律法规的。</p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    <strong>本公司保留随时更新本声明的权利，当声明发生修改时，我们会通过连窗或通知的方式告知您变更后的声明。如您继续使用我们的服务，即视为您同意我们的变更后的隙私政策我们才会按照更新的声明收集、使用、存储您的个人信息。本声明自更新之日起生效。</strong>
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    <strong>
                        <br />
                    </strong>
                </span>
            </p>
            <p>
                <span style="font-size: 16px;">
                    <strong>六、未成年保护</strong>
                </span>
            </p>
            <p>
                <strong style="font-size: 16px; white-space: normal;"></strong>
            </p>
            <p>我们高度重视未成年人个人信息的保护问题，并持续探索更新的未成年人个人信息保护方式。</p>
            <p>我们会积极按照国家防沉迷政策要求，通过启用防沉迷系统保护未成年人的合法权益。我们会通过实名身份信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中。另外，我们会收集您的登录时间、游戏时长信息，通过从系统层面自动干预和限制未成年人游戏时间、启用强制下线功能等方式，引导未成年人合理游戏，并在疑似未成年人消费后尝试联系其监护人进行提醒、确认与处理，帮助未成年人健康上网。
            </p>
            <p>另外，我们还可能会通过收集您在游戏过程中产生的游戏行为数据（您在终端设备操作游戏时形成的游戏记录、充值记录）来初步判断您是否是未成年人。</p>
            <p>若您是未成年人的法定监护人，请您关注您所监护的未成年人是否是在取得您的授权同意之后使用游戏的服务或提供其个人信息。如果您对您所监护的未成年人的个人信息有疑问，请通过本协议约定的联系方式与我们联系。</p>
            <p>特别地，若您是不满14周岁的儿童，为保护儿童个人信息，我们发布了《儿童隐私保护指引》。儿童及其监护人在使用游戏服务前，还应仔细阅读《儿童隐私保护指引》。只有在取得监护人对《儿童隐私保护指引》的同意后，您方可使用我司提供的游戏服务。
            </p>
            <p>
                <strong>注意：《儿童隐私保护指引》是适用于不满14周岁的儿童用户的个人信息保护规则。在《儿童隐私保护指引》的适用范围内，如其与《用户注册协议》、本协议存在不一致或矛盾之处，请以《儿童隐私保护指引》为准。</strong>
                <br />
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"></span>
                <span style="font-size: 16px;">
                    <strong>七、关于第三方SDK的特殊说明</strong>
                </span>
            </p>
            <p>
                <span
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">我们的产品或服务可能接入第三方插件、链接到第三方网站或者允许您将我们产品或服务的部分内容分享到第三方网站或者应用（上述第三方统称“独立第三方”）。不同于前文您使用第三方账号登录我们的产品或服务的情况，本条款情形下我们将不会和独立第三方分享您提供给我们的个人信息，但是独立第三方可能单独收集、使用、储存和分享您的个人信息。请您知悉，该等情形下独立第三方对您的个人信息的单独收集、使用、储存和分享行为将适用该独立第三方自己的隐私政策而不是本隐私政策。请您仔细阅读该等隐私政策，如果您对其存在疑问，您可以选择不使用独立第三方提供的服务或者功能。我们将不会为独立第三方对您的个人数据收集、使用、储存和分享行为及其后果承担任何责任。</span>
            </p>
            <p>
                <span
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">以下我们接入的独立第三方列表，以及独立第三方收集的信息、开启的权限和相关的目的：</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    <strong>1、极光分享SDK</strong>
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">服务类型：内容分享服务</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">SDK收集个人信息类型：</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">· 设备信息（IMEI/MAC/Android
                    ID/IDFA/OAID/UAID/OpenUDID/GUID/SIM卡IMSI信息等）：用于识别唯一用户，保证消息分享的精准送达。</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">·
                    网络信息与位置信息（IP地址，WiFi信息，基站信息等相关信息），优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性，实现区域分享的功能。</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">·
                    APP安装列表信息：我们为向您提供智能分享功能，您可以选择开启或关闭这项服务。</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">极光隐私权政策链接：</span>
                <a href="https://www.jiguang.cn/license/privacy"
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; text-decoration: underline;">
                    <span
                        style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">https://www.jiguang.cn/license/privacy</span>
                </a>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    <strong>2、巨量引擎转化SDK</strong>
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">服务类型：广告投放，保障网络及运营安全</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">SDK收集个人信息类型：</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">【必要信息】（开发者与巨量引擎合作所需的基础信息）设备信息：</span>
            </p>
            <p>
                <span
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">【Android】设备品牌、型号、软件系统版本相关信息、oaid、设备IMEI、AndroidID、MEID、硬件序列号、系统属性、操作系统等基础信息</span>
            </p>
            <p>
                <span
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">【IOS】IDFA、IDFV、设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目、系统属性、操作系统等</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    基础信息应用信息 ：开发者应用名、版本号<span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">、</span>
                    运行中的进程等信息
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">其他信息： 运营商信息、设备时区</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">【可选信息】（开发者可以选择是否提供）</span>
            </p>
            <p>
                <span
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">【Android】无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、IMSI、ICCID、WIFI状态、转化事件</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">巨量引擎转化SDK隐私政策链接：</span>
                <a href="https://open.oceanengine.com/labels/7/docs/1708428054592516" target="_self"
                    textvalue="《巨量引擎转化SDK隐私政策》">
                    <span
                        style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">https://open.oceanengine.com/labels/7/docs/1708428054592516</span>
                </a>
            </p>
            <p>
                <br />
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    <strong>3、腾讯Bugly SDK</strong>
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">服务类型：识别渠道用户，保障网络及运营安全</span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                    SDK收集个人信息类型：设备标识信息（如IMEI、IMSI、MAC地址、Android ID、ICCID<span
                        style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">、</span>
                    SD卡数据等）
                </span>
            </p>
            <p>
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">腾讯Bugly SDK隐私政策链接：</span>
                <a href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56" target="_self"
                    textvalue="《Bugly SDK个人信息保护规则》"
                    style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; text-decoration: underline;">
                    <span
                        style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</span>
                </a>
            </p>
            <p>
                <br style="white-space: normal;" />
            </p>
            <p style="text-align:right;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">最近的更新日期：2023 年 1 月 13 日</span>
            </p>
        </div>
    </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
h1 {
    font-family: 微软雅黑;
    color: rgb(121, 121, 121);
    letter-spacing: 0pt;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    font-size: 22.5pt;
}

.content {
    margin-top: 20px;
    padding-left: 10px;

    font-family: "微软雅黑";
    font-size: 1rem;
    line-height: 2.5rem;
    margin: 0px;
    padding: 0px;
    color: #4d4d4d;
}

#xieyi {
    margin: 0px;
    padding: 20px;
}

#xieyi p {
    text-indent: 2em;
    margin: 1rem;
    padding: 0px;
}

.yans {
    font-weight: bold;
    margin-top: 10px;
}

p {
    color: rgb(121, 121, 121);
    font-size: 18px;
    margin: 0;
}
</style>
